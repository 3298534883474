<style lang="scss">
#client-location {
  .table-form-control {
    width: 100px !important;
    float: right;
  }
}
#client-service {
  input[type="file"] {
    display: none;
  }
}
.custom-file-upload {
  padding: 6px 12px;
  cursor: pointer;
  background: #1bc5bd;
  color: white !important;
  border-radius: 3px;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-center input[type="checkbox"] {
  margin-right: 0.5rem;
}
</style>

<template>
  <div class="row" id="client-location">
    <div class="col-md-12">
      <div class="card form-body">
        <div class="card-body">
          <form action="">
            <div class="row">
              <div class="col-md-7">
                <legend><h6>Location Information</h6></legend>
                <hr />

                <div class="row">
                  <div class="form-group col-md-5">
                    <label for="name"
                      >Code <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      name="name"
                      id=""
                      class="form-control"
                      v-model="formData.slug"
                      :class="errors['slug'] ? 'border border-danger' : ''"
                    />
                    <span v-if="errors['slug']" class="text-danger">{{
                      errors["slug"][0]
                    }}</span>
                  </div>
                  <div class="form-group col-md-7">
                    <label for="name"
                      >Name <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      name="name"
                      id=""
                      class="form-control"
                      v-model="formData.name"
                      :class="errors['name'] ? 'border border-danger' : ''"
                    />
                    <span v-if="errors['name']" class="text-danger">{{
                      errors["name"][0]
                    }}</span>
                  </div>
                  <div class="form-group col-md-12">
                    <label for="description"
                      >Description <span class="text-danger">*</span></label
                    >
                    <textarea
                      name="description"
                      id=""
                      cols="30"
                      rows="1"
                      class="form-control"
                      v-model="formData.description"
                      :class="
                        errors['description'] ? 'border border-danger' : ''
                      "
                    ></textarea>
                    <span v-if="errors['description']" class="text-danger">{{
                      errors["description"][0]
                    }}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="">QuickBooks Display Name <span class="text-danger">*</span></label>
                      <input type="text" class="form-control" v-model="formData.quickbook_display_name"
                      :class="
                        errors['quickbook_display_name'] ? 'border border-danger' : ''
                      "
                        name="" id="" />
                        <span v-if="errors['quickbook_display_name']" class="text-danger">{{
                      errors["quickbook_display_name"][0]
                    }}</span>
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="display_name"
                      >Display Name for Scheduler
                    </label>
                    <input
                      type="text"
                      name="display_name"
                      class="form-control"
                      v-model="formData.display_name"
                      :class="
                        errors['display_name'] ? 'border border-danger' : ''
                      "
                    />
                    <span v-if="errors['display_name']" class="text-danger">{{
                      errors["display_name"][0]
                    }}</span>
                  </div>
                </div>
                <div class="row">
                  <h6></h6>
                  <hr />
                  <div class="form-group col-md-6">
                    <label for="address"
                      >Address Line 1 <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      name="address"
                      class="form-control"
                      v-model="formData.address"
                      :class="errors['address'] ? 'border border-danger' : ''"
                    />
                    <span v-if="errors['address']" class="text-danger">{{
                      errors["address"][0]
                    }}</span>
                  </div>

                  <div class="form-group col-md-6">
                    <label for="address">Address Line 2 </label>
                    <input
                      type="text"
                      name="address"
                      class="form-control"
                      v-model="formData.address2"
                      :class="errors['address2'] ? 'border border-danger' : ''"
                    />
                    <span v-if="errors['address2']" class="text-danger">{{
                      errors["address2"][0]
                    }}</span>
                  </div>
                </div>
                <div class="row">
                  <h6></h6>
                  <hr />

                  <div class="form-group col-md-6">
                    <label for="longitutde"
                      >State <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      name="state"
                      class="form-control"
                      v-model="formData.state"
                      :class="errors['state'] ? 'border border-danger' : ''"
                    />
                    <span v-if="errors['state']" class="text-danger">{{
                      errors["state"][0]
                    }}</span>
                  </div>

                  <div class="form-group col-md-6">
                    <label for="city"
                      >City <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      name="city"
                      class="form-control"
                      v-model="formData.city_name"
                      :class="errors['city_name'] ? 'border border-danger' : ''"
                    />
                    <span v-if="errors['city_name']" class="text-danger">{{
                      errors["city_name"][0]
                    }}</span>
                  </div>
                </div>
                <div class="row">
                  <h6></h6>
                  <hr />
                  <div class="form-group col-md-6">
                    <label for="zipcode"
                      >Zip Code <span class="text-danger">*</span></label
                    >
                    <input
                      type="text"
                      name="zipcode"
                      v-mask="'#####'"
                      class="form-control"
                      v-model="formData.zipcode"
                      :class="errors['zipcode'] ? 'border border-danger' : ''"
                    />
                    <span v-if="errors['zipcode']" class="text-danger">{{
                      errors["zipcode"][0]
                    }}</span>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="client_z"
                      >Timezone<span class="text-danger">*</span></label
                    >

                    <select
                      v-model="formData.client_timezone"
                      class="form-control"
                      :class="
                        errors['client_timezone'] ? 'border border-danger' : ''
                      "
                    >
                      <option value="">Select time-zone</option>

                      <option
                        v-for="(item, index) in timeZones"
                        :key="index"
                        :value="item"
                      >
                        {{ item }}
                      </option>
                    </select>
                    <span
                      v-if="errors['client_timezone']"
                      class="text-danger"
                      >{{ errors["client_timezone"][0] }}</span
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <label for="county"
                      >County <span class="text-danger">*</span></label
                    >
                    <select
                      v-model="formData.county"
                      class="form-control"
                      :class="errors['county'] ? 'border border-danger' : ''"
                    >
                      <option value="">Select county</option>

                      <option value="Alachua">Alachua</option>
                      <option value="Baker">Baker</option>
                      <option value="Bay">Bay</option>
                      <option value="Bradford">Bradford</option>
                      <option value="Brevard">Brevard</option>
                      <option value="Broward">Broward</option>

                      <option value="Calhoun">Calhoun</option>
                      <option value="Charlotte">Charlotte</option>
                      <option value="Citrus">Citrus</option>
                      <option value="Clay">Clay</option>
                      <option value="Collier">Collier</option>
                      <option value="Columbia">Columbia</option>
                      <option value="Desoto">Desoto</option>
                      <option value="Dixie">Dixie</option>
                      <option value="Duval">Duval</option>
                      <option value="Escambia">Escambia</option>
                      <option value="Flagler">Flagler</option>
                      <option value="Franklin">Franklin</option>
                      <option value="Gadsden">Gadsden</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Gilchrist">Gilchrist</option>
                      <option value="Glades">Glades</option>
                      <option value="Gulf">Gulf</option>
                      <option value="Hamilton">Hamilton</option>
                      <option value="Hardee">Hardee</option>
                      <option value="Hendry">Hendry</option>
                      <option value="Hernando">Hernando</option>
                      <option value="Highlands">Highlands</option>
                      <option value="Hillsborough">Hillsborough</option>
                      <option value="Holmes">Holmes</option>
                      <option value="Indian River">Indian River</option>
                      <option value="Jackson">Jackson</option>
                      <option value="Jefferson">Jefferson</option>
                      <option value="Lafayette">Lafayette</option>
                      <option value="Lake">Lake</option>
                      <option value="Lee">Lee</option>
                      <option value="Leon">Leon</option>
                      <option value="Levy">Levy</option>
                      <option value="Liberty">Liberty</option>
                      <option value="Madison">Madison</option>
                      <option value="Manatee">Manatee</option>
                      <option value="Marion">Marion</option>
                      <option value="Martin">Martin</option>
                      <option value="Miami‐Dade">Miami‐Dade</option>
                      <option value="Monroe">Monroe</option>
                      <option value="Nassau">Nassau</option>
                      <option value="Okaloosa">Okaloosa</option>
                      <option value="Okeechobee">Okeechobee</option>
                      <option value="Orange">Orange</option>
                      <option value="Osceola">Osceola</option>
                      <option value="Palm Beach">Palm Beach</option>
                      <option value="Pasco">Pasco</option>
                      <option value="Pinellas">Pinellas</option>
                      <option value="Polk">Polk</option>
                      <option value="Putnam">Putnam</option>
                      <option value="Santa Rosa">Santa Rosa</option>
                      <option value="Sarasota ">Sarasota</option>
                      <option value="Seminole">Seminole</option>
                      <option value="St. Johns">St. Johns</option>
                      <option value="St. Lucie">St. Lucie</option>
                      <option value="Sumter">Sumter</option>
                      <option value="Suwannee">Suwannee</option>
                      <option value="Taylor">Taylor</option>
                      <option value="Union">Union</option>
                      <option value="Volusia">Volusia</option>
                      <option value="Wakulla">Wakulla</option>
                      <option value="Walton">Walton</option>
                      <option value="Washington">Washington</option>
                      <option value="Other States">Other States</option>
                    </select>
                    <span v-if="errors['county']" class="text-danger">{{
                      errors["county"][0]
                    }}</span>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="">Contact Number</label>
                      <input
                        type="text"
                        v-mask="'(###)-###-####'"
                        class="form-control"
                        v-model="formData.phone_number"
                        name=""
                        id=""
                        :class="
                          errors['phone_number'] ? 'border border-danger' : ''
                        "
                      />
                      <span v-if="errors['phone_number']" class="text-danger">{{
                        errors["phone_number"][0]
                      }}</span>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <label for="">Show Contact </label>
                      <input
                        type="text"
                        v-mask="'(###)-###-####'"
                        class="form-control"
                        v-model="formData.show_contact"
                        name=""
                        id=""
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <h6></h6>
                  <hr />

                  <div class="form-group col-md-2 mt-2">
                    <label>Active</label>
                    <span class="switch">
                      <input
                        type="checkbox"
                        v-model="is_active"
                        class="switch"
                        id="switch-normal"
                      />
                      <label for="switch-normal"></label>
                    </span>
                  </div>
                  <div class="form-group col-md-3 mt-2">
                    <label>Accept On-Site Payment</label>
                    <span class="switch">
                      <input
                        type="checkbox"
                        v-model="formData.enable_on_site_payment"
                        id="switch-on-site-payment"
                      />
                      <label for="switch-on-site-payment"></label>
                    </span>
                  </div>
                  <div class="form-group col-md-2 mt-2">
                    <label>Mobile Location</label>
                    <span class="switch">
                      <input
                        type="checkbox"
                        v-model="formData.is_mobile_fingerprinting"
                        id="switch-mobile-fingerprinting"
                      />
                      <label for="switch-mobile-fingerprinting"></label>
                    </span>
                  </div>
                  <div class="form-group col-md-2 mt-2">
                    <label>Card Scan</label>
                    <span class="switch">
                      <input
                        type="checkbox"
                        v-model="formData.card_scan"
                        id="switch-card-scan"
                      />
                      <label for="switch-card-scan"></label>
                    </span>
                  </div>
                  <div class="form-group col-md-2 mt-2">
                    <label>Photo Enabled</label>
                    <span class="switch">
                      <input
                        type="checkbox"
                        v-model="formData.photo_enabled"
                        id="switch-photo-enabled"
                      />
                      <label for="switch-photo-enabled"></label>
                    </span>
                  </div>
                  <div class="form-group col-md-6">
                    <div class="journey" v-if="showImage">
                      <canvas
                        id="imageCanvas"
                        ref="imageCanvas"
                        style="width: 120px"
                      ></canvas>
                      <!-- <image-crop
                        :image_base64="image_base64"
                        :aspectRatioheight="getAspectheight"
                        :aspectRatiowidth="getAspectwidth"
                        :canvasheight="getCanvasheight"
                        :canvaswidth="getCanvasheight"
                        @base64Img="changeImage"
                      ></image-crop> -->
                    </div>

                    <label class="custom-file-upload">
                      <input
                        type="file"
                        id="imageLoader"
                        @change="updateCanvasImage"
                        hidden
                        :class="errors['image'] ? 'border border-danger' : ''"
                      />
                      <i class="fa fa-upload mr-2" style="color: white"></i>
                      Upload Image
                    </label>
                    <br />
                    <span v-if="errors['image']" class="text-danger">{{
                      errors["image"][0]
                    }}</span>
                  </div>
                </div>
              </div>
              <div
                class="col-md-5"
                style="border-left: 1px solid rgb(238, 238, 238)"
              >
                <div class="row">
                  <div class="col-md-12">
                    <legend>
                      <h6>Select Your Location</h6>
                      <span v-if="errors['long']" class="text-danger"
                        >Please select location</span
                      >
                    </legend>

                    <hr />
                    <keep-alive>
                      <GoogleMap
                        ref="map"
                        @getFullAddress="setFullAddress"
                        @dragPointer="setLocation"
                        @onSearchClick="setLocation"
                      ></GoogleMap>
                    </keep-alive>
                  </div>
                  <div class="col-md-12">
                    <legend><h6>User Information</h6></legend>
                    <hr />

                    <div class="row">
                      <div class="form-group col-md-6">
                        <label for=""
                          >First Name <span class="text-danger">*</span></label
                        >
                        <input
                          type="text"
                          name=""
                          class="form-control"
                          id=""
                          v-model="formData.first_name"
                          :class="
                            errors['first_name'] ? 'border border-danger' : ''
                          "
                        />
                        <span v-if="errors['first_name']" class="text-danger">{{
                          errors["first_name"][0]
                        }}</span>
                      </div>
                      <div class="form-group col-md-6">
                        <label for=""
                          >Last Name <span class="text-danger">*</span></label
                        >
                        <input
                          type="text"
                          name=""
                          class="form-control"
                          id=""
                          v-model="formData.last_name"
                          :class="
                            errors['last_name'] ? 'border border-danger' : ''
                          "
                        />
                        <span v-if="errors['last_name']" class="text-danger">{{
                          errors["last_name"][0]
                        }}</span>
                      </div>
                    </div>
                    <div class="form-group">
                      <label for=""
                        >Email <span class="text-danger">*</span></label
                      >
                      <input
                        type="email"
                        name=""
                        class="form-control"
                        id=""
                        v-model="formData.email"
                        :class="errors['email'] ? 'border border-danger' : ''"
                      />
                      <span v-if="errors['email']" class="text-danger">{{
                        errors["email"][0]
                      }}</span>
                    </div>

                    <div class="form-group">
                      <label for=""
                        >Notification Email
                        <span class="text-danger">*</span></label
                      >
                      <input
                        type="text"
                        name=""
                        class="form-control"
                        id=""
                        v-model="formData.notification_email"
                        :class="
                          errors['notification_email']
                            ? 'border border-danger'
                            : ''
                        "
                      />
                    </div>
                    <span
                      v-if="errors['notification_email']"
                      class="text-danger"
                      >{{ errors["notification_email"][0] }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="loading_data">
              <div class="col-md-12">
                <legend><h6>Service List</h6></legend>
                <span v-if="serviceError" class="text-danger">{{
                  serviceError
                }}</span>
                <hr />
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th colspan="2">
                        <!-- <input
                          type="text"
                          name=""
                          class="form-control table-form-control float-left"
                          placeholder="Search Service"
                          id=""
                          v-model="serchInput"
                        /> -->
                      </th>
                      <th colspan="5" class="text-center">Default Rate</th>
                      <th colspan="5"></th>
                    </tr>
                    <tr>
                      <th class="text-left">
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          v-model="selectAll"
                          @change="selectAllServices()"
                        />
                      </th>
                      <th class="text-left">Service</th>
                      <th class="text-right">Rate</th>
                      <th class="text-right">Add.Rate</th>
                      <th class="text-right">Expedited Rate</th>
                      <th class="text-right">Tax(%)</th>
                      <th class="text-right">Commission($)</th>
                      <th class="text-right">Rate</th>
                      <th class="text-right">Add.Rate</th>
                      <th class="text-right">Expedited Rate</th>
                      <th class="text-right">Tax(%)</th>
                      <th class="text-right">Commission($)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(service, index) in service_table_data"
                      :key="index"
                    >
                      <td class="text-left">
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          v-model="service.checked"
                        />
                      </td>
                      <td class="text-left">{{ service.service }}</td>
                      <td class="text-right">{{ service.default_rate }}</td>
                      <td class="text-right">
                        {{ service.default_additional_rate }}
                      </td>
                      <td class="text-right">
                        {{ service.default_expedited_rate }}
                      </td>
                      <td class="text-right">{{ service.default_tax }}</td>
                      <td class="text-right">
                        {{ service.default_commission }}
                      </td>
                      <td>
                        <input
                          type="number"
                          min="0"
                          name=""
                          class="form-control table-form-control"
                          id=""
                          v-model="service.rate"
                        />
                      </td>
                      <td
                        :class="
                          service.has_additional_rate ? 'flex-center' : ''
                        "
                      >
                        <div
                          class="input-group mb-3"
                          v-if="service.has_additional_rate"
                        >
                          <input
                            type="text"
                            class="form-control"
                            aria-label="Text input with checkbox"
                            v-model="service.additional_rate"
                          />
                        </div>
                      </td>
                      <td>
                        <div  v-if="service.service_code == 'apostille'"
                          class="input-group mb-3"
                        >
                          <input
                            type="text"
                            class="form-control"
                            aria-label="Text input with checkbox"
                            v-model="service.expedited_rate"
                          />
                        </div>
                      </td>
                      <td>
                        <input
                          type="number"
                          min="0"
                          name=""
                          class="form-control table-form-control"
                          id=""
                          v-model="service.tax_percentage"
                        />
                      </td>
                      <td>
                        <input
                          type="number"
                          min="0"
                          name=""
                          class="form-control table-form-control"
                          id=""
                          v-model="service.commission_percentage"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row" v-else>
              <span>No Service Available</span>
            </div>
          </form>
        </div>
        <div class="card-footer">
          <button
            v-if="isEdit"
            class="btn btn-success"
            @click="update('kt_update_service')"
            style="float: right"
            ref="kt_update_service"
          >
            <i class="fa fa-check"></i>Update
          </button>
          <button
            v-if="!isEdit"
            class="btn btn-success ml-3"
            style="float: right"
            @click="store('S', 'kt_save_service')"
            ref="kt_save_service"
          >
            <i class="fa fa-check"></i>Save
          </button>
          <button
            v-if="!isEdit"
            class="btn btn-success"
            style="float: right"
            @click="store('SAA', 'kt_save_and_add_service')"
            ref="kt_save_and_add_service"
          >
            <i class="fa fa-check"></i>Save and Add Another
          </button>
          <button
            ref="kt_save_add_another_company"
            class="btn btn-danger mr-3"
            @click="goBack()"
            style="float: right"
          >
            <i class="fa fa-arrow-left"></i> Back
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoogleMap from "@/components/GoogleMap.vue";
// import ImageCrop from "@/components/ImageCrop";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import {
  FETCH_B_SERVICES,
  STORE_B_LOCATION,
} from "@/core/services/store/actions.type";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      isEdit: false,
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard",
        },
        {
          id: 2,
          title: "Locations",
          route: "client.location",
        },
        {
          id: 3,
          title: "Create New Location",
          route: "",
        },
      ],
      service_name: "",
      description: "",
      service_type: "",
      formData: {
        name: "",
        description: "",
        display_name: "",
        address: "",
        address2: "",
        state: "",
        city_name: "",
        zipcode: "",
        long: "",
        lat: "",
        client_timezone: "",
        first_name: "",
        last_name: "",
        email: "",
        username: "",
        slug: "",
        notification_email: "",
        is_mobile_fingerprinting: false,
        enable_on_site_payment: true,
        phone_number: "",
        show_contact: "",
        card_scan: false,
        photo_enabled: false,
        county: "",
        quickbook_display_name: "",
      },
      service_table_data: [],
      is_active: true,
      showImage: false,
      image_base64: "",
      loading_data: true,
      errors: [],
      serviceError: "",
      selectAll: false,
      serchInput: "",
      timeZones: [],
      image_crop_base64: "",
      regEmail:
        /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    };
  },
  computed: {
    ...mapGetters([
      "getBServices",
      "getAspectheight",
      "getAspectwidth",
      "getCanvasheight",
      "getCanvasheight",
    ]),
    filteredServices() {
      let tempService = this.getBServices.data;
      //process search input
      if (this.searchInput != "" && this.searchInput) {
        tempService = tempService.filter((item) => {
          return item.name
            .toUpperCase()
            .includes(this.searchInput.toUpperCase());
        });
      }
      return tempService;
    },
  },
  components: {
    GoogleMap,
  },
  created() {
    this.timeZones = this.$moment.tz.names();
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.isEdit = this.$route.name == "client.service.edit" ? true : false;
    if (this.isEdit) {
      this.breadCrumbs[2].title = "Edit | Name of Location";
    }
    this.getServiceData();
  },
  watch: {
    getBServices: {
      handler(val) {
        if (val.data && val.data.length > 0) {
          this.service_table_data = [];
          val.data.map((service) => {
            this.service_table_data.push({
              id: service.id,
              service: service.name,
              service_code: service.service_type_code,
              rate: "",
              has_additional_rate: service.has_additional_rate,
              additional_rate: "",
              tax_percentage: "",
              commission_percentage: "",
              expedited_rate: "",
              checked: false,
              default_rate: service.default_rate,
              default_additional_rate: service.additional_rate,
              default_tax: service.tax_percentage,
              default_commission: service.commission_percentage,
              default_expedited_rate: service.default_expedited_rate,
            });
          });
        }
      },
    },
  },
  methods: {
    setFullAddress(data) {
      // console.log(data);
      this.formData.address = data;
    },

    setLocationDragged(location) {
      this.formData.long = location.lng;
      this.formData.lat = location.lat;
    },
    setLocation(location) {
      this.formData.long = location.lng;
      this.formData.lat = location.lat;
      this.formData.city_name = location.city.long_name;
      this.formData.address = location.formatted_address;
      this.formData.zipcode = location.postal_code.long_name;

      let state = location.state.short_name.toUpperCase();
      this.formData.client_timezone = "";
      //   let time_zone =  this.$moment().utcOffset(parseInt(location.utc_offset_minutes)).format('Z');
      //   alert(time_zone);
      //  let z =  time_zone.tz.zone.name;
      //  alert(z);

      // let country = this._.find(this.countries, (o) => { return o.code.toUpperCase() == location.country.short_name.toUpperCase() });

      // console.log(this._.find(this.states, (o) => { return o.code.toUpperCase() == location.state.short_name.toUpperCase() }), location.state.short_name.toUpperCase())
      // this.formData.country = country === undefined ? '' : country ;
      this.formData.state = state === undefined ? "" : state;
    },
    goBack() {
      var client_slug = this.$route.params.client_slug;
      this.$router.push({
        name: "client.location",
        params: { client_slug: client_slug },
      });
    },
    selectAllServices() {
      if (this.selectAll) {
        this.service_table_data.filter((service) => {
          return (service.checked = true);
        });
      } else {
        this.service_table_data.filter((service) => {
          return (service.checked = false);
        });
      }
    },

    validateEmail(value) {
      if (this.regEmail.test(value)) {
        this.errors["email"] = "";
      } else {
        this.errors["email"] = {
          0: "Please enter a valid email address",
        };
      }
    },
    updateEmail(e) {
      this.validateEmail(e.target.value);
    },
    closeBtnLoad(ref) {
      const submitButton = this.$refs[ref];
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
      submitButton.disabled = false;
    },
    setClientSlug(e) {
      var value = e.target.value;
      this.formData.slug = value.replace(/\s+/g, "").toLowerCase();
    },
    loadingButton(ref) {
      const submitButton = this.$refs[ref];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
    },
    clearForm() {
      this.service_table_data = [];
      this.formData.name = "";
      this.formData.description = "";
      this.formData.display_name = "";
      this.formData.address = "";
      this.formData.city_name = "";
      this.formData.zipcode = "";
      this.formData.long = "";
      this.formData.lat = "";
      this.formData.client_timezone = "";
      this.formData.first_name = "";
      this.formData.last_name = "";
      this.formData.email = "";
      this.formData.username = "";
      this.formData.slug = "";
      this.formData.address2 = "";
      this.formData.state = "";
      this.formData.notification_email = "";
      this.formData.image = "";
      this.formData.enable_on_site_payment = true;
      this.formData.is_mobile_fingerprinting = false;
      this.formData.card_scan = false;
      this.formData.photo_enabled = false;
      this.formData.county = "";
      this.is_active = true;
      this.showImage = false;
      this.loading_data = true;
      this.image_base64 = "";
      this.serviceError = "";
      this.quickbook_display_name = "";
      this.errors = [];
      this.getServiceData();
    },
    changeImage(val) {
      this.image_crop_base64 = val;
    },
    store(saveType, ref) {
      var service_table_data_ = this.service_table_data.filter(
        (service) => service.checked == true
      );
      if (service_table_data_.length == 0) {
        this.$toastr.e("Please select at least one service");
      } else {
        this.errors = [];
        this.loadingButton(ref);
        //  if (this.showImage) {
        //   this.formData.image = this.image_crop_base64;
        // } else {
        //   this.formData.image = "none";
        // }
        this.formData.image = this.image_base64;
        this.formData.user_name =
          this.formData.first_name + " " + this.formData.last_name;
        let _data = {};
        this.formData.username = this.formData.email;
        this.formData.notification_email =
          this.formData.notification_email != ""
            ? this.formData.notification_email.replace(" ", "")
            : "";
        _data.user_detail = this.formData;
        _data.user_detail.slug = _data.user_detail.slug.replace(/\s/g, "");

        _data.rate_list = service_table_data_;
        _data.is_active = this.is_active;
        _data.client_slug = this.$route.params.client_slug;

        this.$store
          .dispatch(STORE_B_LOCATION, _data)
          .then((data) => {
            this.$toastr.s(data.msg);
            this.closeBtnLoad(ref);
            //saveType = S =  Save Only and go back to list where SAA =  save and add another or stay in same page
            if (saveType == "S") {
              this.$router.push({ name: "client.location" });
            } else if (saveType == "SAA") {
              this.clearForm();
            }
          })
          .catch((err) => {
            this.errors = err;
            this.closeBtnLoad(ref);
          });
      }
    },
    update(ref) {
      this.loadingButton(ref);
      alert("Update");
      this.$router.push({ name: "client.location" });
    },
    getServiceData() {
      this.loadingData = true;
      this.$store
        .dispatch(FETCH_B_SERVICES, { status: true })
        .then(() => {
          this.loadingData = false;
        })
        .catch(() => {
          this.loadingData = false;
        });
    },
    updateCanvasImage(e) {
      this.showImage = true;
      var self = this;

      var reader,
        files = e.target.files;

      reader = new FileReader();

      reader.onload = (e) => {
        this.e = e;
        var img = new Image();
        img.onload = function () {
          self.drawCanvasImage(img);
        };
        img.src = e.target.result;
      };

      reader.readAsDataURL(files[0]);
    },

    drawCanvasImage(imageObj) {
      // const canvas = document.createElement("canvas");

      // canvas.width = imageObj.width;
      // canvas.height = imageObj.height;

      // var context = canvas.getContext("2d");
      // // ctx.drawImage(img, 0, 0,img.width/2,img.height/2);
      // context.drawImage(imageObj, 0, 0);

      // // var canvas = document.getElementById("imageCanvas");
      // var base64 = canvas.toDataURL("image/png");
      // this.image_base64 = base64;

      var canvas = this.$refs.imageCanvas;
      canvas.width = 120;
      canvas.height = (100 * imageObj.height) / imageObj.width;

      var context = canvas.getContext("2d");
      // ctx.drawImage(img, 0, 0,img.width/2,img.height/2);
      context.drawImage(
        imageObj,
        0,
        0,
        120,
        (100 * imageObj.height) / imageObj.width
      );

      // var canvas = document.getElementById("imageCanvas");
      var base64 = canvas.toDataURL("image/png");
      this.image_base64 = base64;
    },
  },
};
</script>

<style scoped>
input[type="number"] {
  -moz-appearance: textfield !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
